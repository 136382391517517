import LanguageSelector from '@/components/LanguageSelector';
import CompanyService from '../services/company.service';
import PrioritySelect from '@/components/PrioritySelect';
import DatePicker from '../components/DatePicker.vue';
import Store from '@/store';

export default {
    // Check if this section can be rendered and accessed, this param IS OPTIONAL (true by default)
    // NOTICE: this route will not be added to VueRouter AT ALL if this check fails
    // MUST be a function that returns a boolean
    accessCheck: async () => Store.getters['user/user'].roles_access.is_admin,

    scope: 'company',

    order: 0,

    route: {
        // After processing this route will be named as 'settings.exampleSection'
        name: 'company.settings.general',

        // After processing this route can be accessed via URL 'settings/example'
        path: '/company/general',

        meta: {
            // After render, this section will be labeled as 'Example Section'
            label: 'settings.general',

            // Service class to gather the data from API, should be an instance of Resource class
            service: new CompanyService(),

            // Renderable fields array
            fields: [
                {
                    label: 'field.auto_thin',
                    key: 'auto_thinning',
                    fieldOptions: {
                        type: 'switch',
                        placeholder: 'field.auto_thin',
                    },
                    tooltipValue: 'tooltip.auto_thin',
                },
                {
                    label: 'field.default_priority',
                    key: 'default_priority_id',
                    render: (h, props) => {
                        const value = props.values.default_priority_id ?? 0;

                        return h(PrioritySelect, {
                            props: {
                                value,
                                clearable: true,
                            },
                            on: {
                                input(value) {
                                    props.inputHandler(value);
                                },
                            },
                        });
                    },
                },
                {
                    label: 'field.start_date_tax',
                    key: 'start_date_tax_year',
                    render: (h, props) => {
                        const value = props.values.start_date_tax_year ?? '';

                        return h(DatePicker, {
                            props: {
                                initialDate: value,
                            },
                            on: {
                                input(value) {
                                    props.inputHandler(value);
                                },
                            },
                        });
                    },
                },
                {
                    label: 'settings.company_language',
                    key: 'language',
                    render: (h, props) => {
                        const lang = props.values.language ?? 'en';

                        return h(LanguageSelector, {
                            props: {
                                value: lang,
                            },
                            on: {
                                setLanguage(lang) {
                                    props.inputHandler(lang);
                                },
                            },
                        });
                    },
                },
                {
                    label: 'field.name',
                    key: 'name',
                    render: (h, props) => {
                        const value = props.values.company.name;
                        return h('at-input', {
                            props: {
                                value: value,
                            },
                            on: {
                                input(value) {
                                    props.inputHandler(value);
                                },
                            },
                        });
                    },
                },
                {
                    label: 'field.description',
                    key: 'description',
                    render: (h, props) => {
                        const value = props.values.company.description;
                        return h('at-textarea', {
                            props: {
                                value: value,
                            },
                            on: {
                                input(value) {
                                    props.inputHandler({ description: value });
                                },
                            },
                        });
                    },
                },
                {
                    label: 'field.company_number',
                    key: 'company_number',
                    render: (h, props) => {
                        const value = props.values.company.company_number;
                        return h('at-input', {
                            props: {
                                value: value,
                            },
                            on: {
                                input(value) {
                                    props.inputHandler(value);
                                },
                            },
                        });
                    },
                },
                {
                    label: 'field.street_address',
                    key: 'street_address',
                    render: (h, props) => {
                        const value = props.values.company.street_address;
                        return h('at-input', {
                            props: {
                                value: value,
                            },
                            on: {
                                input(value) {
                                    props.inputHandler(value);
                                },
                            },
                        });
                    },
                },
                {
                    label: 'field.zip',
                    key: 'zip',
                    render: (h, props) => {
                        const value = props.values.company.zip;
                        return h('at-input', {
                            props: {
                                value: value,
                            },
                            on: {
                                input(value) {
                                    props.inputHandler(value);
                                },
                            },
                        });
                    },
                },
                {
                    label: 'field.city',
                    key: 'city',
                    render: (h, props) => {
                        const value = props.values.company.city;
                        return h('at-input', {
                            props: {
                                value: value,
                            },
                            on: {
                                input(value) {
                                    props.inputHandler(value);
                                },
                            },
                        });
                    },
                },
                {
                    label: 'field.url',
                    key: 'url',
                    render: (h, props) => {
                        const value = props.values.company.url;
                        return h('at-input', {
                            props: {
                                value: value,
                            },
                            on: {
                                input(value) {
                                    props.inputHandler(value);
                                },
                            },
                        });
                    },
                },
                {
                    label: 'field.country',
                    key: 'country',
                    render: (h, data) => {
                        return h('span', data.values.country);
                    },
                },
                {
                    label: 'field.currency',
                    key: 'currency',
                    render: (h, data) => {
                        return h('span', data.currentValue);
                    },
                },
            ],
        },
    },
};
