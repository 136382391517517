export const ModuleConfig = {
    routerPrefix: 'settings',
    loadOrder: 11,
    moduleName: 'Subscriptions',
};

export function init(context, router) {
    context.addCompanySection(require('./sections/subscriptions').default(context, router));

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}
