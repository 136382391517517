import ResourceService from '@/services/resource.service';
import axios from 'axios';

export default class InvitationService extends ResourceService {
    getAll(config = {}) {
        return axios.get('api/v1/invitations/list', config);
    }

    save(data, isNew = false) {
        return axios.post(`api/v1/invitations`, data);
    }

    resend(id) {
        return axios.put(`api/v1/invitations/${id}`);
    }

    deleteItem(id) {
        return axios.delete(`api/v1/invitations/${id}`);
    }

    getWithFilters(filters, config = {}) {
        return axios.post('api/v1/invitations/list', filters, config);
    }
}
