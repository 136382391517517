import axios from 'axios';

class DocumentService {
    save(data) {
        return axios.post('/api/v1/documents', data);
    }

    getAll(data) {
        return axios.post('/api/v1/documents/list', data);
    }

    update(data, id) {
        return axios.put(`/api/v1/documents/${id}`, data);
    }

    getItem(id) {
        return axios.get(`/api/v1/documents/${id}`);
    }
}

export default DocumentService;
