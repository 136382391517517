import SubscriptionPolicy from '../../policies/subscription.policy';
import RolePolicy from '../../policies/role.policy';

export const ModuleConfig = {
    routerPrefix: 'invoice',
    loadOrder: 23,
    moduleName: 'Invoice',
};

export function init(context) {
    context.addRoute({
        path: '/invoice',
        name: 'invoice',
        component: () => import(/* webpackChunkName: "invoice" */ './views/Invoice.vue'),
        meta: {
            auth: true,
            BasicSubscription: true,
        },
        children: [
            {
                path: 'upload',
                name: 'invoice.upload',
                component: () => import(/* webpackChunkName: "invoice" */ './views/invoice/Upload.vue'),
                meta: {
                    auth: true,
                    BasicSubscription: true,
                    checkPermission: () =>
                        SubscriptionPolicy.viewTab('basicPlan') && RolePolicy.haveRole(['admin', 'accountant']),
                },
            },
            {
                path: 'outgoing',
                name: 'invoice.outgoing',
                component: () => import(/* webpackChunkName: "invoice" */ './views/invoice/Outgoing.vue'),
                meta: {
                    auth: true,
                    BasicSubscription: true,
                    checkPermission: () =>
                        SubscriptionPolicy.viewTab('basicPlan') && RolePolicy.haveRole(['admin', 'accountant']),
                },
            },
            {
                path: 'corrections',
                name: 'invoice.corrections',
                component: () => import(/* webpackChunkName: "invoice" */ './views/invoice/Corrections.vue'),
                meta: {
                    auth: true,
                    BasicSubscription: true,
                    checkPermission: () =>
                        SubscriptionPolicy.viewTab('basicPlan') && RolePolicy.haveRole(['admin', 'accountant']),
                },
            },
            {
                path: 'view/:id',
                name: 'invoice.view',
                component: () => import(/* webpackChunkName: "invoice" */ './views/invoice/upload_actions/View.vue'),
                meta: {
                    auth: true,
                    BasicSubscription: true,
                    checkPermission: () =>
                        SubscriptionPolicy.viewTab('basicPlan') && RolePolicy.haveRole(['admin', 'accountant']),
                },
            },
            {
                path: 'edit/:id',
                name: 'invoice.edit',
                component: () => import(/* webpackChunkName: "invoice" */ './views/invoice/upload_actions/Edit.vue'),
                meta: {
                    auth: true,
                    BasicSubscription: true,
                    checkPermission: () =>
                        SubscriptionPolicy.viewTab('basicPlan') && RolePolicy.haveRole(['admin', 'accountant']),
                },
            },
        ],
    });

    context.addNavbarEntry({
        label: 'navigation.invoices',
        to: {
            path: '/invoice/upload',
        },
        displayCondition: () => {
            return SubscriptionPolicy.viewTab('basicPlan') && RolePolicy.haveRole(['admin', 'accountant']);
        },
    });

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}
