<!-- AddAvatar.vue -->
<template>
    <div v-if="currentMeta != 'users/create'" class="avatar-form">
        <div v-if="avatarUrl != ''" class="avatar-preview">
            <img :src="avatarUrl" alt="Current Avatar" />
            <div class="form-right">
                <form class="form" @submit.prevent="handleSubmit">
                    <div class="form-group">
                        <label for="avatar" class="avatar-label">{{ $t('settings.user.avatar.change') }}</label>
                        <input
                            id="avatar"
                            type="file"
                            accept="image/*"
                            class="avatar-input"
                            @change="handleFileChange"
                        />
                    </div>
                    <div class="form-group">
                        <button type="submit" class="submit-button">{{ $t('settings.user.avatar.upload') }}</button>
                        <button class="remove-button" @click="removeAvatar">
                            {{ $t('settings.user.avatar.remove') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div v-else>
            <form class="form" @submit.prevent="handleSubmit">
                <div class="form-group">
                    <label for="avatar" class="avatar-label">{{ $t('settings.user.avatar.upload') }}</label>
                    <input id="avatar" type="file" accept="image/*" class="avatar-input" @change="handleFileChange" />
                </div>
                <div class="form-group">
                    <button type="submit" class="submit-button">{{ $t('settings.user.avatar.upload') }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import AvatarService from '../services/avatar.service';

    export default {
        props: {
            src: {
                type: String,
            },
            user_id: {
                type: Number,
            },
        },
        data() {
            return {
                avatar: '',
                currentMeta: this.$route.meta.permissions,
                service: new AvatarService(),
            };
        },

        computed: {
            avatarUrl() {
                if (this.src) {
                    return `${process.env.VUE_APP_API_URL}/${this.src}`;
                }
                return '';
            },
        },

        methods: {
            handleFileChange(event) {
                const file = event.target.files[0];
                if (file) {
                    this.avatar = file;
                }
            },
            async handleSubmit() {
                try {
                    const formData = new FormData();
                    formData.append('picture', this.avatar);
                    formData.append('user_id', this.user_id);
                    const res = !this.avatarUrl
                        ? await this.service.save(formData)
                        : await this.service.update(formData, this.user_id);
                    this.$emit('avatarSelected', this.avatar);

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.screenshot.save.success.title'),
                        message: this.$t('notification.screenshot.save.success.message'),
                    });

                    window.location.reload();
                } catch (response) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(response ? response : 'request to Avatars is canceled');
                    }
                }
            },

            async removeAvatar() {
                try {
                    await this.service.destroy(this.user_id);

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.screenshot.save.success.title'),
                        message: this.$t('notification.screenshot.save.success.message'),
                    });
                    window.location.reload();
                } catch (response) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(response ? response : 'request to Avatar delete is canceled');
                    }
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .avatar-form {
        float: left;
        max-width: 400px;
        margin: auto;
    }

    img {
        border-radius: 8px;
        width: 200px;
        height: 190px;
    }

    .avatar-preview {
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        height: auto;
        border-radius: 4px;
        margin-bottom: 10px;
    }

    .theme-dark .form {
        background-color: $border-color-dark;
    }

    .form {
        margin-left: 50px;
        width: 200px;
        background-color: #f8f8f8;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        .remove-button {
            width: 134px;
            background-color: #f44336; /* Red color for delete/remove actions */
            color: white;
            padding: 8px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
            margin-top: 10px; /* Add some spacing from the image */
        }

        .remove-button:hover {
            background-color: #d32f2f; /* Darker red on hover */
        }
    }

    .form-group {
        margin-bottom: 15px;
    }

    .avatar-label {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333;
        .theme-dark & {
            color: #ffa500;
        }
    }

    .icon {
        margin-right: 10px;
    }

    .avatar-input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .submit-button {
        background-color: #4caf50;
        color: white;
        padding: 10px 15px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
    }

    .submit-button:hover {
        background-color: #45a049;
    }
</style>
