<template>
    <div class="services">
        <div class="header">
            <h1>{{ $t('page.currentSubscription.subscriptions') }}</h1>
            <button v-if="subscriptions.length > 0 && currentSubscription" class="back-button" @click="goBack">
                {{ $t('page.buttons.back') }}
            </button>
        </div>
        <div class="service-list">
            <div v-if="subscriptions.length > 0">
                <div v-for="subscription in subscriptions" :key="subscription.attributes.id" class="service-card">
                    <div class="card-header">
                        <h2>{{ subscription.attributes.name }}</h2>
                    </div>
                    <p>{{ subscription.attributes.description }}</p>
                    <div class="buttons">
                        <button class="more-button" @click="buyPlan(subscription.id)">
                            {{ $t('page.buttons.subscribe') }}
                        </button>
                    </div>
                </div>
            </div>
            <div v-else class="no-data">{{ $t('page.currentSubscription.noData') }}</div>
        </div>
    </div>
</template>

<script>
    import SubscriptionService from '../services/subscription.service';

    export default {
        data() {
            return {
                subscriptions: [],
                currentSubscription: null,
                showModal: false,
                subscriptionToUnfollow: null,
                service: new SubscriptionService(),
            };
        },
        methods: {
            async buyPlan(subscriptionId) {
                try {
                    await this.service.subscribe(subscriptionId);

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.save.success.title'),
                        message: this.$t('notification.save.success.message'),
                    });

                    this.$store.dispatch('company/fetchCompanyData');
                    this.$router.push({ name: 'company.subscriptions.current' });
                } catch {
                    console.log('Error Subscribe request');
                }
            },
            async fetchSubscriptions() {
                try {
                    const { data } = await this.service.getAll();
                    this.subscriptions = data.data;
                } catch {
                    console.log('REquest Subscription Error');
                }
            },
            goBack() {
                this.$router.go(-1);
            },
        },
        created() {
            this.fetchSubscriptions();
            this.currentSubscription = this.$store.getters['company/company'].subscription;
        },
    };
</script>

<style scoped lang="scss">
    .services {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .header h1 {
        margin: 0;
    }

    .header .back-button {
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        background-color: #dc3545;
        color: white;
        border: none;
        border-radius: 4px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #c82333;
        }

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .service-list {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .service-card {
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        width: 100%;
        position: relative;
        margin-top: 10px;
        text-align: left;

        .theme-dark & {
            border-color: #333;
            background-color: #333;
        }
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .service-card h2 {
        margin: 0 0 10px;
    }

    .service-card p {
        margin: 0 0 10px;

        .theme-dark & {
            color: #c4c4cf;
        }
    }

    .buttons {
        display: flex;
        gap: 10px;
    }

    .more-button,
    .unfollow-button {
        padding: 10px 20px;
        font-size: 14px;
        cursor: pointer;
        color: white;
        border: none;
        border-radius: 4px;
        transition: background-color 0.3s;

        &:hover {
            color: #c4c4cf;
        }
    }

    .more-button {
        background-color: #007bff;

        &:hover {
            background-color: #0056b3;
        }

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .no-data {
        text-align: center;
        font-size: 18px;
        color: #999;
        margin: 20px 0;

        .theme-dark & {
            color: #c4c4cf;
        }
    }
</style>
