<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'company.subscriptions',

        beforeRouteEnter(to, from, next) {
            if (to.path === '/company/subscriptions/' || to.path === '/company/subscriptions') {
                next('/company/subscriptions/current');
            } else {
                next();
            }
        },

        beforeRouteUpdate(to, from, next) {
            if (to.path === '/company/subscriptions/' || to.path === '/company/subscriptions') {
                this.$router.replace('/company/subscriptions/current').catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            } else {
                next();
            }
        },
    };
</script>
