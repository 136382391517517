import AvatarService from '../services/avatar.service';
import AvatarForm from '../views/AvatarForm.vue';

export function fieldsProvider() {
    return [
        {
            key: 'id',
            displayable: () => false,
        },
        {
            label: 'field.avatar',
            key: 'avatar',
            rules: 'required',
            render: (h, props) => {
                return h(AvatarForm, {
                    on: {
                        avatarSelected: avatar => {
                            props.inputHandler(avatar);
                        },
                    },
                });
            },
        },
    ];
}

export const config = { fieldsProvider };

export default {
    // Check if this section can be rendered and accessed, this param IS OPTIONAL (true by default)
    // NOTICE: this route will not be added to VueRouter AT ALL if this check fails
    // MUST be a function that returns a boolean
    accessCheck: () => true,

    order: 14,
    route: {
        // After processing this route will be named as 'settings.exampleSection'
        name: 'settings.user.avatar.title',

        // After processing this route can be accessed via URL 'settings/example'
        path: '/settings/avatar',

        meta: {
            // After render, this section will be labeled as 'Example Section'
            label: 'settings.user.avatar.label',

            // Service class to gather the data from API, should be an instance of Resource class
            service: new AvatarService(),

            // Renderable fields array
            get fields() {
                return config.fieldsProvider();
            },
        },
    },
};
