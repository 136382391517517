const actions = {
    timePerProject({ state, getters }, payload) {
        console.log(payload);
    },

    projectsDataList({ state, getters }, data) {
        const result = data.reduce((acc, current) => {
            const projectId = current.attributes.project.id;
            const userId = current.attributes.user.id;
            const projectName = current.attributes.project.attributes.project_name;
            const projectTotalTime = current.attributes.project.attributes.project_total_time;

            // Find existing project index in accumulator
            const existingProjectIndex = acc.findIndex(item => item.id === projectId);

            if (existingProjectIndex === -1) {
                // If the project doesn't exist in the accumulator, add it
                acc.push({
                    id: projectId,
                    attributes: {
                        project_name: projectName,
                        project_total_time: projectTotalTime,
                        intervals: [current], // Add the current interval as the first interval
                        users: [current.attributes.user], // Add the current user
                        tasks: [current.attributes.task], // Add the current task
                    },
                });
            } else {
                // If the project already exists, push the current interval and task
                acc[existingProjectIndex].attributes.intervals.push(current);

                // Check if the user with the same ID already exists in the users array
                const existingUserIndex = acc[existingProjectIndex].attributes.users.findIndex(
                    user => user.id === userId,
                );
                if (existingUserIndex === -1) {
                    // If the user doesn't exist, add it
                    acc[existingProjectIndex].attributes.users.push(current.attributes.user);
                }

                // Add the task if it doesn't exist
                const existingTaskIndex = acc[existingProjectIndex].attributes.tasks.findIndex(
                    task => task.id === current.attributes.task.id,
                );
                if (existingTaskIndex === -1) {
                    acc[existingProjectIndex].attributes.tasks.push(current.attributes.task);
                }
            }

            return acc;
        }, []);
        return result;
    },
};

export default {
    actions,
};
