import SubscriptionPolicy from '../../policies/subscription.policy';

export const ModuleConfig = {
    routerPrefix: 'service-order',
    loadOrder: 23,
    moduleName: 'service-order',
};

export function init(context) {
    context.addRoute({
        path: '/service-order/:service_id',
        name: 'services-order',
        component: () => import(/* webpackChunkName: "service" */ './views/ServiceOrder.vue'),
        meta: {
            auth: true,
            BasicSubscription: true,
        },
        displayCondition: () => {
            return SubscriptionPolicy.viewTab('basicPlan');
        },
    });

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}
