<template>
    <div class="at-select">
        <v-select
            :options="options"
            label="label"
            :placeholder="$t('time_intervals.task_select.placeholder')"
            :clearable="false"
            @input="inputHandler($event.attributes.id)"
            @search="onSearch"
        >
            <div slot="no-options">{{ $t('time_intervals.task_select.no_options') }}</div>
        </v-select>
        <i class="icon icon-chevron-down at-select__arrow" />
    </div>
</template>

<script>
    import vSelect from 'vue-select';

    export default {
        name: 'LazySelect',
        components: {
            vSelect,
        },
        props: {
            value: {
                type: Number,
            },
            userID: {
                type: Number,
            },
            service: {
                type: Object,
                required: true,
            },
            inputHandler: {
                type: Function,
                required: true,
            },
        },
        data() {
            return {
                options: [],
            };
        },

        async created() {
            this.options = await this.service.getWithFilters({}).then(({ data }) => {
                return data.data.map(task => {
                    const label =
                        typeof task.project !== 'undefined'
                            ? `${task.attributes.task_name} (${task.attributes.project.attributes.project_name})`
                            : task.attributes.task_name;

                    return { ...task, label };
                });
            });
        },
        methods: {
            onSearch(query, loading) {
                if (query.length >= 3) {
                    this.fetchTasks(query, loading);
                } else {
                    this.options = [];
                }
            },
            async fetchTasks(query, loading) {
                loading(true);
                try {
                    this.options = await this.service
                        .getWithFilters({ company_tasks: true, disable_pagy: true, user_id: this.userID })
                        .then(({ data }) => {
                            loading(false);
                            if (data.data == undefined) {
                                return [];
                            }
                            return data.data.map(task => {
                                const label =
                                    typeof task.project !== 'undefined'
                                        ? `${task.attributes.task_name} (${task.attributes.project.attributes.project_name})`
                                        : task.attributes.task_name;

                                return { ...task, label };
                            });
                        });
                } catch {
                    console.log('Search failed');
                    loading(false);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .at-select__arrow {
        .theme-dark & {
            color: #ffa500;
        }
    }

    .vs__dropdown-menu {
        background-color: #ffa500;
    }

    .vs__search {
        color: #ffa500;
    }
    .at-select {
        &::v-deep {
            .vs__dropdown-menu {
                .theme-dark & {
                    background-color: #333;
                }
            }

            .vs__selected {
                .theme-dark & {
                    color: #ffa500;
                }
            }

            .vs__selected-options {
                .theme-dark & {
                    background-color: #555;
                    color: #ffa500;
                }
            }

            .vs__dropdown-option {
                .theme-dark & {
                    background-color: #333;
                    color: #ffa500;
                    &:hover {
                        background-color: #555;
                    }
                }
            }

            #vs1__combobox {
                .theme-dark & {
                    background-color: #555;
                    color: #ffa500;
                    border-color: #333;
                }
            }
        }
    }
</style>
