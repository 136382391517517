import axios from '@/config/app';
import ResourceService from '@/services/resource.service';

export default class StatusService extends ResourceService {
    getAll(config = {}) {
        return axios.post('api/v1/statuses/list', config);
    }

    getItemRequestUri(id) {
        return `api/v1/statuses/${id}`;
    }

    getItem(id, filters = {}) {
        return axios.get(this.getItemRequestUri(id));
    }

    save(data, isNew = false) {
        if (typeof data.active === 'undefined') {
            data.active = true;
        }

        return axios.post('api/v1/statuses', data);
    }

    update(data, id) {
        return axios.put(`api/v1/statuses/${id}`, data);
    }

    deleteItem(id) {
        return axios.delete(`api/v1/statuses/${id}`);
    }

    getWithFilters(filters, config = {}) {
        return axios.post('api/v1/statuses/list', filters, config);
    }
}
