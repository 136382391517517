<template>
    <div class="services">
        <div class="header">
            <button
                class="at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large subscriptions-button"
                @click="subscriptionsList()"
            >
                {{ $t('page.currentSubscription.subscriptions') }}
            </button>
        </div>
        <div class="service-list">
            <div v-if="!!subscription">
                <h2>{{ $t('page.currentSubscription.title') }}</h2>
                <div class="service-card">
                    <div class="card-header">
                        <h2>{{ this.subscription.name }}</h2>
                        <p>{{ daysLeft }} {{ $t('page.currentSubscription.daysLeft') }}</p>
                    </div>
                    <p>{{ this.truncate(this.subscription.description) }}</p>
                    <p>
                        {{ $t('page.currentSubscription.start') }}
                        {{ this.formatDate(this.companySubscription.start_date) }}
                    </p>
                    <p>
                        {{ $t('page.currentSubscription.end') }}
                        {{ this.formatDate(this.companySubscription.end_date) }}
                    </p>
                    <div class="buttons">
                        <button class="more-button" @click="goToView(subscription.id)">
                            {{ $t('page.buttons.more') }}
                        </button>
                        <div v-if="this.companySubscription.auto_subscription" class="unsub-button">
                            <button class="unfollow-button" @click="openUnfollowModal(subscription.id)">
                                {{ $t('page.buttons.unsubscribe') }}
                            </button>
                        </div>
                        <div v-else class="sub-button">
                            <button class="follow-button" @click="openUnfollowModal(subscription.id)">
                                {{ $t('page.buttons.subscribe') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="no-data">{{ $t('page.currentSubscription.noData') }}</p>
            </div>
        </div>

        <Modal
            v-if="showModal"
            :visible="showModal"
            :message="message"
            @close="showModal = false"
            @confirm="autoSubscription"
        />
    </div>
</template>

<script>
    import moment from 'moment-timezone';
    import { mapGetters } from 'vuex';
    import Modal from '../components/SubscriptionModal.vue';
    import CompanyService from '../../../services/company.service';
    import SubscriptionService from '../services/subscription.service';

    export default {
        components: {
            Modal,
        },
        data() {
            return {
                subscription: null,
                companySubscription: null,
                showModal: false,
                companyService: new CompanyService(),
                subscriptionService: new SubscriptionService(),
            };
        },
        computed: {
            ...mapGetters('dashboard', ['timezone']),
            daysLeft() {
                const start = new Date();
                const end = new Date(this.companySubscription.end_date);
                const difference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
                return difference;
            },
            message() {
                const followMessage = this.$t('page.currentSubscription.follow');
                const unfollowMessage = this.$t('page.currentSubscription.unfollow');
                return this.companySubscription.auto_subscription ? unfollowMessage : followMessage;
            },
        },
        methods: {
            openUnfollowModal(subscription_id) {
                this.showModal = true;
            },
            async autoSubscription() {
                try {
                    this.showModal = false;
                    const { data } = await this.subscriptionService.autoSubscription(
                        !this.companySubscription.auto_subscription,
                    );

                    this.companySubscription = data.data.attributes;
                    this.$store.dispatch('company/setCompanySubscription', data.data);

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.save.success.title'),
                        message: this.$t('notification.save.success.message'),
                    });
                } catch {
                    console.log('Error Unfollow request');
                }
            },
            subscriptionsList() {
                this.$router.push({ name: 'company.subscriptions.list' });
            },
            goToView(subscription_id) {
                this.$router.push({ path: `/company/subscriptions/view/${subscription_id}` });
            },
            async fetchCompanySubscriptions() {
                try {
                    const user = this.$store.getters['user/user'];
                    const res = await this.companyService.getById(user.current_company_id);
                    if (!res.subscription) {
                        this.subscriptionsList();
                    }
                    const subModel = res.subscription.attributes.subscription;
                    this.subscription = subModel;
                    this.companySubscription = res.subscription.attributes;
                } catch {
                    console.log('Company Request error');
                }
            },
            formatDate(date) {
                return moment.tz(date, this.timezone).locale(this.$i18n.locale).format('MMMM D, YYYY — HH:mm:ss (Z)');
            },
            truncate(value, words = 100) {
                return value.length >= words ? value.substring(0, words) + '...' : value;
            },
        },
        created() {
            this.fetchCompanySubscriptions();
        },
    };
</script>

<style scoped lang="scss">
    .services {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .header {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 10px 20px;
    }

    .subscriptions-button {
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
    }

    .service-list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin-left: 100px;
        margin-bottom: 20px;
    }

    .service-card {
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        width: 427px;
        margin-top: 10px;
        text-align: left;

        .theme-dark & {
            border-color: #333;
            background-color: #333;
        }
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .service-card h2 {
        margin: 0 0 10px;
    }

    .service-card p {
        margin: 0 0 10px;

        .theme-dark & {
            color: #c4c4cf;
        }
    }

    .buttons {
        display: flex;
        gap: 10px;
    }

    .more-button,
    .follow-button,
    .unfollow-button {
        padding: 10px 20px;
        font-size: 14px;
        cursor: pointer;
        color: white;
        border: none;
        border-radius: 4px;
        transition: background-color 0.3s;

        &:hover {
            color: #c4c4cf;
        }
    }

    .more-button {
        background-color: #007bff;

        &:hover {
            background-color: #0056b3;
        }

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .unfollow-button {
        background-color: #dc3545;

        &:hover {
            background-color: #c82333;
        }

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .follow-button {
        background-color: #28a745;

        &:hover {
            background-color: #218838;
        }

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .no-data {
        font-size: 18px;
        text-align: center;
        float: left;
        margin-left: 200px;
        margin-top: 20px;
        .theme-dark & {
            color: #c4c4cf;
        }
    }
</style>
