<template>
    <div class="subscription-details">
        <div class="header">
            <button class="at-btn crud__control-items__item at-btn--primary at-btn--large back-button" @click="goBack">
                {{ $t('page.buttons.back') }}
            </button>
        </div>
        <div v-if="companySubscription" class="sub-info card">
            <div class="header-info">
                <h2>{{ $t('page.view.info') }}</h2>
                <p>{{ daysLeft }} {{ $t('page.view.daysLeft') }}</p>
            </div>
            <div class="card-body">
                <p>
                    <strong>{{ $t('page.view.name') }}</strong> {{ companySubscription.subscription.name }}
                </p>
                <p>
                    <strong>{{ $t('page.view.active') }}</strong> {{ companySubscription.active }}
                </p>
                <p>
                    <strong>{{ $t('page.view.autoSubscription') }}</strong> {{ companySubscription.auto_subscription }}
                </p>
                <p>
                    <strong>{{ $t('page.view.start') }}</strong> {{ formatDate(companySubscription.start_date) }}
                </p>
                <p>
                    <strong>{{ $t('page.view.end') }}</strong> {{ formatDate(companySubscription.end_date) }}
                </p>
                <p>
                    <strong>{{ $t('page.view.description') }}</strong>
                    {{ companySubscription.subscription.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import SubscriptionService from '../services/subscription.service';
    export default {
        name: 'SubscriptionDetails',
        data() {
            return {
                companySubscription: null,
                service: new SubscriptionService(),
            };
        },
        created() {
            this.fetchSubscription();
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            formatDate(dateString) {
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                return new Date(dateString).toLocaleDateString(undefined, options);
            },
            async fetchSubscription() {
                try {
                    const { data } = await this.service.getCompanySubscription();
                    this.companySubscription = data.data.attributes;
                } catch {
                    console.log('Error Show Subscription Request!');
                }
            },
        },
        computed: {
            daysLeft() {
                const start = new Date(this.companySubscription.start_date);
                const end = new Date(this.companySubscription.end_date);
                const difference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
                return difference;
            },
        },
    };
</script>

<style scoped lang="scss">
    .subscription-details {
        padding: 20px;
        border-radius: 5px;
        max-width: 600px;
        margin: 0 auto;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .sub-info {
        margin-top: 20px;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        .theme-dark & {
            border-color: #333;
            background-color: #333;
        }
    }

    .header-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .card-body {
        margin-top: 10px;
    }

    .subscription-details h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .subscription-details p {
        margin: 5px 0;
    }

    p {
        .theme-dark & {
            color: #c4c4cf;
        }
    }

    strong {
        .theme-dark & {
            color: #ffa500;
        }
    }
</style>
