<template>
    <div class="invite-form">
        <validation-observer ref="form">
            <div v-for="(user, index) in users" :key="index" class="row invite-form__group">
                <validation-provider v-slot="{ errors }" :vid="`users.${index}.email`" class="col-14">
                    <at-input
                        v-model="user.email"
                        :placeholder="$t('field.email')"
                        :status="errors.length > 0 ? 'error' : ''"
                    >
                        <template slot="prepend">{{ $t('field.email') }}</template>
                    </at-input>
                    <small>{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider v-slot="{ errors }" :vid="`users.${index}.role_ids`" class="col-6">
                    <role-select-multiple
                        v-model="user.role_ids"
                        @input="handleRoleIdsChange(index, $event)"
                    ></role-select-multiple>
                </validation-provider>
                <at-button
                    v-if="index > 0"
                    class="col-2 invite-form__remove at-btn--primary"
                    @click="removeUser(index)"
                >
                    <i class="icon icon-x"></i>
                </at-button>
            </div>
        </validation-observer>
        <at-button type="default" size="small" class="col-4 at-btn--primary" @click="handleAdd">{{
            $t('control.add')
        }}</at-button>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import RoleSelectMultiple from '@/components/RoleSelectMultiple';

    export default {
        name: 'InviteInput',
        components: {
            ValidationObserver,
            ValidationProvider,
            RoleSelectMultiple,
        },
        data() {
            return {
                users: [{ email: null, role_ids: [] }],
            };
        },
        mounted() {
            this.$emit('input', this.users);
        },
        methods: {
            handleAdd() {
                this.users.push({ email: null, role_ids: [] });
            },
            removeUser(index) {
                this.users.splice(index, 1);
            },
            handleRoleIdsChange(userIndex, roleIds) {
                this.$set(this.users, userIndex, {
                    ...this.users[userIndex],
                    role_ids: roleIds,
                });
            },
        },
        watch: {
            users(value) {
                this.$emit('input', value);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .invite-form {
        &__group {
            margin-bottom: 1rem;
            align-items: center;
        }

        &__remove {
            max-height: 40px;
        }

        /* Styling for the Save button */
        .col-4 {
            margin-top: 1rem; /* Adjust spacing between buttons */
        }
    }
</style>
