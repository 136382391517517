// import SubscriptionPolicy from '../../policies/subscription.policy';

// export const ModuleConfig = {
//     routerPrefix: 'screenshots',
//     loadOrder: 20,
//     moduleName: 'Screenshots',
// };

export function init(context) {
    //     context.addRoute({
    //         path: '/screenshots',
    //         name: 'screenshots',
    //         component: () => import(/* webpackChunkName: "screenshots" */ './views/Screenshots.vue'),
    //         meta: {
    //             auth: true,
    //             DevelopmentSubscription: true,
    //             checkPermission: () => true,
    //         },
    //     });

    //     context.addNavbarEntry({
    //         label: 'navigation.screenshots',
    //         to: {
    //             name: 'screenshots',
    //         },
    //         displayCondition: () => SubscriptionPolicy.viewTab('developmentPlan'),
    //     });

    //     context.addLocalizationData({
    //         en: require('./locales/en'),
    //         ru: require('./locales/ru'),
    //         ua: require('./locales/ua'),
    //         pl: require('./locales/pl'),
    //         de: require('./locales/de'),
    //         fr: require('./locales/fr'),
    //     });

    return context;
}
