import RolePolicy from '../../policies/role.policy';

export const ModuleConfig = {
    routerPrefix: 'document',
    loadOrder: 23,
    moduleName: 'Document',
};

export const ROLES_ACCESS = ['admin', 'manager'];

export default {
    ROLES_ACCESS: ROLES_ACCESS,
};

export function init(context) {
    context.addRoute({
        path: '/document',
        name: 'document',
        component: () => import(/* webpackChunkName: "invoice" */ './views/Document.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: 'list/:id',
                name: 'document.list',
                component: () => import(/* webpackChunkName: "invoice" */ './views/document/List.vue'),
                meta: {
                    auth: true,
                    checkPermission: () => RolePolicy.haveRole(ROLES_ACCESS),
                },
            },
            {
                path: 'view/:id',
                name: 'document.view',
                component: () => import(/* webpackChunkName: "invoice" */ './views/document/View.vue'),
                meta: {
                    auth: true,
                    checkPermission: () => RolePolicy.haveRole(ROLES_ACCESS),
                },
            },
            {
                path: 'new/:id',
                name: 'document.new',
                component: () => import(/* webpackChunkName: "invoice" */ './views/document/New.vue'),
                meta: {
                    auth: true,
                    checkPermission: () => RolePolicy.haveRole(ROLES_ACCESS),
                },
            },
            {
                path: 'edit/:id',
                name: 'document.edit',
                component: () => import(/* webpackChunkName: "invoice" */ './views/document/Edit.vue'),
                meta: {
                    auth: true,
                    checkPermission: () => RolePolicy.haveRole(ROLES_ACCESS),
                },
            },
        ],
    });

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}
