<template>
    <div>{{ name }}</div>
</template>
<script>
    export default {
        props: {
            name: {
                required: true,
            },
        },
    };
</script>
