import axios from '@/config/app';
import ResourceService from '@/services/resource.service';

export default class SubscribtionService extends ResourceService {
    async getAll(config = {}) {
        return await axios.get('api/v1/subscriptions', config);
    }

    async subscribe(subscriptionId) {
        return await axios.post('/api/v1/subscriptions/subscribe', { subscription_id: subscriptionId });
    }

    async autoSubscription(data) {
        return await axios.post('/api/v1/subscriptions/auto-subscribe', { auto_subscription: data });
    }

    async getCompanySubscription() {
        return await axios.get('/api/v1/company_subscription');
    }
}
