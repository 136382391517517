import cloneDeep from 'lodash/cloneDeep';
import SubscribtionService from '../services/subscription.service';
import Subscriptions from '../views/Subscriptions.vue';
import SubList from '../components/List.vue';
import SubView from '../components/View.vue';
import CurrentSubscription from '../components/CurrentSubscription.vue';

export default (context, router) => {
    const subscriptionsContext = cloneDeep(context);
    subscriptionsContext.routerPrefix = 'company/subscriptions';

    const crud = subscriptionsContext.createCrud('subscriptions.crud-title', 'subscriptions', SubscribtionService);
    const crudEditRoute = crud.edit.getEditRouteName();
    const crudNewRoute = crud.new.getNewRouteName();

    const navigation = { edit: crudEditRoute, new: crudNewRoute };

    crud.new.addToMetaProperties('permissions', 'subscriptions/create', crud.new.getRouterConfig());
    crud.new.addToMetaProperties('afterSubmitCallback', () => router.go(-1), crud.new.getRouterConfig());

    crud.edit.addToMetaProperties('permissions', 'subscriptions/edit', crud.edit.getRouterConfig());

    const grid = subscriptionsContext.createGrid('subscriptions.grid-title', 'subscriptions', SubscribtionService);
    grid.addToMetaProperties('navigation', navigation, grid.getRouterConfig());
    grid.addToMetaProperties('permissions', () => true, grid.getRouterConfig());

    return {
        accessCheck: async () => true,
        scope: 'company',
        order: 23,
        component: Subscriptions,
        route: {
            name: 'Subscriptions.crud.subscriptions',
            path: '/company/subscriptions',
            meta: {
                label: 'subscriptions.grid-title',
                service: new SubscribtionService(),
            },

            children: [
                {
                    name: 'company.subscriptions.current',
                    path: 'current',
                    service: new SubscribtionService(),
                    component: CurrentSubscription,
                },
                {
                    name: 'company.subscriptions.list',
                    path: 'list',
                    service: new SubscribtionService(),
                    component: SubList,
                },
                {
                    name: 'company.subscriptions.view',
                    path: 'view/:id',
                    service: new SubscribtionService(),
                    component: SubView,
                },
                {
                    ...grid.getRouterConfig(),
                    path: '',
                },
                ...crud.getRouterConfig(),
            ],
        },
    };
};
