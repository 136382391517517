import ResourceService from '@/services/resource.service';
import axios from 'axios';

export default class UserService extends ResourceService {
    getAll(config = {}) {
        return axios.get('api/v1/users', { params: config });
    }

    save(data, isNew = false) {
        return axios.post(`users/${isNew ? 'create' : 'edit'}`, data);
    }

    deleteItem(id) {
        return axios.post('users/remove', { id });
    }

    getOptionLabelKey() {
        return 'full_name';
    }

    getWithFilters(filters, config = {}) {
        return axios.post('users/list', filters, config);
    }

    sendInvite(id) {
        return axios.post('users/send-invite', { id });
    }
}
