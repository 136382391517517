import SubscriptionPolicy from '../../policies/subscription.policy';
import RolePolicy from '../../policies/role.policy';

export const ModuleConfig = {
    routerPrefix: 'kanban',
    loadOrder: 23,
    moduleName: 'Kanban',
};

export const SUBSCRIPTION_PLAN = 'enterprisePlan';
export const ROLES_ACCESS = ['admin', 'reporter', 'manager', 'employee'];

export default {
    SUBSCRIPTION_PLAN: SUBSCRIPTION_PLAN,
    ROLES_ACCESS: ROLES_ACCESS,
};

export function init(context) {
    context.addRoute({
        path: '/kanban',
        name: 'kanban',
        component: () => import(/* webpackChunkName: "invoice" */ './views/kanban.vue'),
        meta: {
            auth: true,
            EnterpriseSubscription: true,
        },
        children: [
            {
                path: 'board',
                name: 'kanban.board',
                component: () => import(/* webpackChunkName: "invoice" */ './views/kanban/KanbanBoard.vue'),
                meta: {
                    auth: true,
                    EnterpriseSubscription: true,
                    checkPermission: () =>
                        SubscriptionPolicy.viewTab(SUBSCRIPTION_PLAN) && RolePolicy.haveRole(ROLES_ACCESS),
                },
            },
        ],
    });

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}
