<!-- AddAvatar.vue -->
<template>
    <div class="avatar-form">
        <div v-if="avatarUrl" class="avatar-preview">
            <h2 style>{{ $t('settings.user.avatar.title') }}</h2>
            <img :src="avatarUrl" alt="Current Avatar" />
            <button class="remove-button" @click="removeAvatar">{{ $t('settings.user.avatar.remove') }}</button>
        </div>
        <h2>{{ $t('settings.user.avatar.add') }}</h2>
        <form class="form" @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="avatar" class="avatar-label">
                    <span class="icon">📷</span>
                    {{ $t('settings.user.avatar.choose') }}
                </label>
                <input id="avatar" type="file" accept="image/*" class="avatar-input" @change="handleFileChange" />
            </div>
            <div class="form-group">
                <button type="submit" class="submit-button">{{ $t('settings.user.avatar.upload') }}</button>
            </div>
        </form>
    </div>
</template>

<script>
    import AvatarService from '../services/avatar.service';

    export default {
        data() {
            return {
                avatar: '',
                service: new AvatarService(),
                user: {},
            };
        },

        computed: {
            avatarUrl() {
                if (this.user.avatar_url) {
                    return `${process.env.VUE_APP_API_URL}/${this.user.avatar_url}`;
                }
                return '';
            },
        },

        created() {
            this.getUser();
        },

        methods: {
            handleFileChange(event) {
                const file = event.target.files[0];
                if (file) {
                    this.avatar = file;
                }
            },
            async handleSubmit() {
                try {
                    const formData = new FormData();
                    formData.append('picture', this.avatar);
                    const res = !this.avatarUrl
                        ? await this.service.save(formData)
                        : await this.service.update(formData, this.user.id);
                    this.$emit('avatarSelected', this.avatar);

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.screenshot.save.success.title'),
                        message: this.$t('notification.screenshot.save.success.message'),
                    });

                    window.location.reload();
                } catch (response) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(response ? response : 'request to Avatars is canceled');
                    }
                }
            },

            async getUser() {
                try {
                    this.user = await this.service.getAll();
                } catch (response) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(response ? response : 'request to Avatar delete is canceled');
                    }
                }
            },

            async removeAvatar() {
                try {
                    await this.service.destroy(this.user.id);

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.screenshot.save.success.title'),
                        message: this.$t('notification.screenshot.save.success.message'),
                    });
                    window.location.reload();
                } catch (response) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(response ? response : 'request to Avatar delete is canceled');
                    }
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .avatar-form {
        float: left;
        max-width: 400px;
        margin: auto;
    }

    img {
        border-radius: 50%;
        width: 150px;
        height: 150px;
    }

    .avatar-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        height: auto;
        border-radius: 4px;
        margin-bottom: 10px;

        .remove-button {
            width: 150px;
            background-color: #f44336; /* Red color for delete/remove actions */
            color: white;
            padding: 8px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
            margin-top: 10px; /* Add some spacing from the image */
        }

        .remove-button:hover {
            background-color: #d32f2f; /* Darker red on hover */
        }
    }

    .theme-dark .form {
        background-color: $border-color-dark;
    }

    .form {
        background-color: #f8f8f8;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .form-group {
        margin-bottom: 15px;
    }

    .avatar-label {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333;
        .theme-dark & {
            color: #c4c4cf;
        }
    }

    .icon {
        margin-right: 10px;
    }

    .avatar-input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .submit-button {
        background-color: #4caf50;
        color: white;
        padding: 10px 15px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
    }

    .submit-button:hover {
        background-color: #45a049;
    }
</style>
